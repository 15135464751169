<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title v-t="'climbs.log.getApp.header'" />
      <v-spacer />
      <v-btn icon @click="close"><v-icon>tl-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="text--primary d-flex flex-column align-center">
      <div class="headline my-6" v-t="'climbs.log.getApp.title'" />
      <p class="text-center" v-html="$t('climbs.log.getApp.explanation')" />

      <div class="d-flex justify-center flex-wrap">
        <v-img
          v-for="badge in badges"
          :key="badge.name"
          :src="require(`./badges/toplogger-${badge.name}-badge.png`)"
          :alt="badge.altText"
          max-width="240"
          class="ma-4"
          @click="showToastrWarning"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    badges: [
      { name: 'google-play', altText: 'Get it on Google Play' },
      { name: 'app-store', altText: 'Download in the App Store' },
      { name: 'browser', altText: 'Available on any browser' },
    ],
  }),
  computed: {
    ...mapState('kiosk', ['isKiosk']),
  },
  mounted() {
    this.$store.commit('kiosk/setShowGetAppDialog', false)
  },
  methods: {
    ...mapActions('dialog', ['close']),
    showToastrWarning() {
      this.$store.dispatch('toast/error', this.$t('climbs.log.getApp.toastMsg'))
    },
  },
}
</script>
